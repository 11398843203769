const StripePort = {
  add: function (app, publishableKey) {
    if (!window.Stripe) {
      return;
    }

    let stripeElements = {};

    // Cross browser support
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    const mountAttemptsMax = 20;
    let mountAttempts = 0;

    let elements;
    function buildAndMountElementWithSetupIntent(stripeClient, params) {
      const cardElement = document.querySelector(params.selector);

      elements = stripeClient.elements({
        clientSecret: params.setupIntentSecret,
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap",
          },
        ],
        appearance: {
          theme: "flat",
          variables: {
            borderRadius: "3px",
            border: "1px solid #C4DCF8",
            boxShadow: "none",
            colorPrimary: "#006CD1",
            colorText: "#1D2334",
            colorTextPlaceholder: "#497AA5",
            colorTextSecondary: "#497AA5",
            fontFamily: "Wix Madefor Text",
            fontLineHeight: "21px",
            colorBackground: "#FFFFFF",
            fontSize: "1rem",
            colorIconTabSelected: "#0056A6",
            colorIconTabHover: "#0056A6",
          },
          rules: {
            ".DropdownItem--highlight": {
              backgroundColor: "#F1F6FC",
              border: "1px solid #81AFE4",
            },
            ".Tab--selected, .Tab--selected:hover": {
              borderColor: "#0056A6",
              color: "#0056A6",
              backgroundColor: "#f1f6fc",
            },
            ".Tab, .Input, .PickerItem": {
              border: "1px solid #c4dcf8",
              boxShadow: "none",
            },
            ".Input": {
              padding: "10px",
              fontSize: ".875rem",
            },
            ".Label": {
              fontWeight: "850",
              fontSize: "1rem",
              fontLineHeight: "21px",
              marginBottom: "10px",
            },
            ".PickerItem": {
              color: "#497aa4",
              textTransform: "uppercase",
              lineHeight: "25px",
            },
            ".PickerItem:hover": {
              backgroundColor: "#FFFFFF",
            },
            ".Tab:hover, .Input:hover, .PickerItem:hover": {
              borderColor: "#81AFE4",
              color: "#0056A6",
            },
          },
        },
      });
      const disallowedBrands = params.options.rejectAmex ? ["amex"] : [];
      const paymentMethodOrder = params.options.encourageAch ? ["us_bank_account", "card"] : [];
      const paymentElement = elements.create("payment", {
        paymentMethodOrder,
        terms: {
          card: "never",
          usBankAccount: "never",
        },
        disallowedCardBrands: disallowedBrands,
        wallets: {
          applePay: "never",
          googlePay: "never",
        },
      });

      paymentElement.mount(cardElement);
      paymentElement.on("change", function (element) {
        const type = (element && element.value && element.value.type) || "";
        app.ports.stripePortOnSetupIntentChange.send(type);
      });

      return {
        stripeClient: stripeClient,
        elements: elements,
        paymentElement: paymentElement,
        options: params.options,
      };
    }

    function tryBuildAndMountElementWithSetupIntent(params) {
      const stripeClient = Stripe(publishableKey, {
        betas: ["blocked_card_brands_beta_1"],
        apiVersion: "2019-12-03",
        locale: params.locale,
      });
      const cardElement = document.querySelector(params.selector);

      if (cardElement) {
        requestAnimationFrame(function () {
          stripeElements[params.selector] = buildAndMountElementWithSetupIntent(stripeClient, params);
        });
      } else {
        if (mountAttempts <= mountAttemptsMax) {
          mountAttempts += 1;
          setTimeout(function () {
            tryBuildAndMountElementWithSetupIntent(params);
          }, 100);
        }
      }
    }

    app.ports.stripePortBuildAndMountElementWithSetupIntent.subscribe(tryBuildAndMountElementWithSetupIntent);

    app.ports.stripePortConfirmSetupIntent.subscribe(function (params) {
      const stripeElement = stripeElements[params.selector];
      const billingDetails =
        params.options && params.options.billingOptions
          ? {
              billing_details: {
                name: params.options.billingOptions.name,
              },
            }
          : {};

      stripeElement.stripeClient
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: window.location.href, // handle a return url here for 3d secure cards
            payment_method_data: billingDetails,
          },
          redirect: "if_required", // only redirect if needed for 3d secure
        })
        .then(({ error, setupIntent }) => {
          if (error) {
            app.ports.stripePortOnSetupIntentConfirmed.send({
              selector: params.selector,
              setup_intent: null,
              error: error.message,
            });
          } else {
            app.ports.stripePortOnSetupIntentConfirmed.send({
              selector: params.selector,
              setup_intent: setupIntent,
              error: null,
            });
          }
        });
    });
  },
};

module.exports = StripePort;
